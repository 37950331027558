<template>
  <div>
      <div class="vx-row">
          <vx-card>
              <h5 style="margin-bottom:9px">Ventas Realizadas</h5>
              <!-- <span>Reporte basado en las fechas de <strong>venta</strong></span> -->
              <!-- TABLE ACTION ROW -->
              <div class="flex flex-wrap justify-between items-center">
                  <!-- ITEMS PER PAGE -->
                  <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                  <vs-dropdown vs-trigger-click class="cursor-pointer">
                      <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                      <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ sales.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : sales.length }} de {{ sales.length }}</span>
                      <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                      </div>
                      <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                      <vs-dropdown-menu>
                      <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                          <span>20</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                          <span>50</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                          <span>100</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                          <span>150</span>
                      </vs-dropdown-item>
                      </vs-dropdown-menu>
                  </vs-dropdown>
                  </div>

                  <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                  <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
                      <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
                      <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
                      <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
                      <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
                      <vs-input
                          class="mb-4 md:mb-0 mr-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery"
                          placeholder="Buscar..."/>
                      <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
                  </div>
              </div>
              <ag-grid-vue
                  ref="agGridTable"
                  :gridOptions="gridOptions"
                  class="ag-theme-material w-100 my-4 ag-grid-table"
                  :columnDefs="columnDefs"
                  :defaultColDef="defaultColDef"
                  :rowData="sales"
                  @first-data-rendered="onFirstDataRendered"
                  rowSelection="multiple"
                  colResizeDefault="shift"
                  :animateRows="true"
                  :pagination="true"
                  :paginationPageSize="paginationPageSize"
                  :suppressPaginationPanel="true"
                  :enableRtl="$vs.rtl"
                  :modules="modules">
              </ag-grid-vue>
              <ag-grid-vue style="height: 60px; flex: none;"
                  class="ag-theme-material w-100 ag-grid-table total"
                  :gridOptions="bottomGridOptions"
                  :headerHeight="0"
                  :columnDefs="columnDefs"
                  :floatingFiltersHeight="0"
                  :rowData="salesFooter"
                  :modules="modules">
              </ag-grid-vue>
              <vs-pagination
                  :total="totalPages"
                  :max="maxPageNumbers"
                  v-model="currentPage" />
          </vx-card>
      </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import { AgGridVue } from "@ag-grid-community/vue"
import reportModule from '@/store/report/moduleReport.js'
import DatePicker from "vuejs-datepicker";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import * as lang from 'vuejs-datepicker/src/locale';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

export default {
  data() {
      return {
          languages: lang,
          from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()),
          to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),

          //Table
          searchQuery: '',
          gridOptions: {
              alignedGrids: [],
              suppressHorizontalScroll: true
          },
          modules: AllCommunityModules,

          maxPageNumbers: 6,
          gridApi: null,
          defaultColDef: {
              sortable: true,
              editable: false,
              resizable: true,
              suppressMenu: true
          },
          columnDefs: [
              {
              headerName: 'Id',
              field: 'id_sale',
              filter: true,
              sort: 'desc',
              },
              {
              headerName: 'Fecha',
              field: 'sale_datetime',
              width: 160,
              filter: true,
              comparator: params => this.myDateComparator(params ? params.split(' ')[0] : ''),
              valueFormatter: params => params.data.sale_datetime ? `${new Date(params.data.sale_datetime.split(' ')[0]).toLocaleDateString('es-AR', {timeZone: "UTC"})} ${params.data.sale_datetime.split(' ')[1]}` : ''
              },
              {
              headerName: 'Producto',
              field: 'title_product',
              filter: true,
              floatingFilter: true,
              width: 220
              },
              {
              headerName: 'SKU',
              field: 'sku',
              filter: true,
              floatingFilter: true,
              width: 160,
              },
              {
              headerName: 'Categoría',
              field: 'name_category',
              filter: true,
              floatingFilter: true,
              width: 320
              },
              {
              headerName: 'Precio Unit.',
              field: 'price',
              filter: false,
              width: 320,
              valueFormatter: params => this.$options.filters.currencySign(params.data.price, '$ '),
              },
              {
              headerName: 'Cantidad',
              field: 'quantity',
              filter: false,
              width: 320
              },
              {
              headerName: 'Total',
              field: 'total',
              filter: false,
              width: 320,
              valueFormatter: params => this.$options.filters.currencySign(params.data.total, '$ '),
              },
              {
              headerName: 'Comprador',
              field: 'guardian.name',
              filter: true,
              floatingFilter: true,
              width: 320
              },
              {
              headerName: 'Comprador Email',
              field: 'guardian.email',
              filter: true,
              floatingFilter: true,
              width: 320
              },
              {
              headerName: 'Comprador Teléfono',
              field: 'guardian.phone_number',
              filter: true,
              floatingFilter: true,
              width: 320
              },
              {
              headerName: 'Receptor',
              field: 'receiver_name',
              filter: true,
              floatingFilter: true,
              width: 320
              },
              {
              headerName: 'Receptor Documento',
              field: 'document',
              filter: true,
              floatingFilter: true,
              width: 320
              },
              {
              headerName: 'Comentarios',
              field: 'comment',
              filter: true,
              floatingFilter: true,
              width: 320
              },
          ],
          sales: [],
          salesFooter: [],
          bottomGridOptions: {
              alignedGrids: [],
              defaultColDef: {
                  editable: false,
                  sortable: true,
                  resizable: true,
                  filter: true,
                  flex: 1,
                  // minWidth: 100,
                  floatingFiltersHeight: 0
              }
          },
      }
  },
  components : {
      DatePicker,
      VuePerfectScrollbar,
      VueApexCharts,
      AgGridVue
  },

  created() {
      //Modules
      if(!reportModule.isRegistered) {
          this.$store.registerModule('reportModule', reportModule)
          reportModule.isRegistered = true
      }

      if(this.$acl.check('store'))
          this.loadSaleTable();
  },

  methods: {
      isDate(val) {
          return val instanceof Date && !isNaN(val.valueOf())
      },
      loadSaleTable() {
          this.sales = []
          this.salesFooter = []

          this.$store.dispatch("reportModule/getMarketplaceSalesByStore", { id: this.$store.state.AppActiveUser.store.id, params: this.getQueryParams()})
              .then(response => {
                  if(response.length > 0) {
                      let s = JSON.parse(JSON.stringify(response));
                      let s_footer = {};
                      s_footer.id_sale = "Total";
                      s_footer.total = 0;

                      s.forEach(element => {
                          element.sale_date = new Date(element.sale_date).toLocaleDateString('es-AR', this.$localeDateOptions)
                          element.receiver_name = element.name ? `${element.name} ${element.last_name}` : ''
                          s_footer.total += element.total;
                      });

                      this.salesFooter = [s_footer];
                      this.sales = s;
                  }
              })
              .catch(error => {console.log(error)})
      },
      getQueryParams() {
        var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};

        let params = `from=${dates.from}&to=${dates.to}`

        return params
      },
      updateSearchQuery(val) {
          this.gridApi.setQuickFilter(val);
      },
      myDateComparator(date1, date2) {
          var date1Number = this.monthToComparableNumber(date1);
          var date2Number = this.monthToComparableNumber(date2);
          if (date1Number === null && date2Number === null) {
              return 0;
          }
          if (date1Number === null) {
              return -1;
          }
          if (date2Number === null) {
              return 1;
          }
          return date1Number - date2Number;
      },
      monthToComparableNumber(date) {
          if (date === undefined || date === null || date.length !== 10) {
              return null;
          }
          var yearNumber = date.substring(6, 10);
          var monthNumber = date.substring(3, 5);
          var dayNumber = date.substring(0, 2);
          var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
          return result;
      },
      onFirstDataRendered: function() {
          this.gridColumnApi.autoSizeAllColumns();
      }
  },

  watch: {
      from: function () {
          this.loadSaleTable();
      },
      to: function () {
          this.loadSaleTable();
      },
      '$store.state.windowWidth'(val) {
          if(val <= 576) {
              this.maxPageNumbers = 6;
          }
      }
  },
  computed: {
      paginationPageSize() {
          if(this.gridApi) return this.gridApi.paginationGetPageSize()
          else return 50
      },
      totalPages() {
          if(this.gridApi) return this.gridApi.paginationGetTotalPages()
          else return 0
      },
      currentPage: {
          get() {
              if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
              else return 1
          },

          set(val) {
              this.gridApi.paginationGoToPage(val - 1)
          }
      },
  },
  beforeMount() {
    this.gridOptions.alignedGrids.push(this.bottomGridOptions);
    this.bottomGridOptions.alignedGrids.push(this.gridOptions);
  },
  mounted() {
      this.gridApi = this.gridOptions.api
      this.gridColumnApi = this.gridOptions.columnApi

      if(this.$vs.rtl) {
          const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
          header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
      }
  }
}

</script>

<style>

  .ag-cell{
      text-transform: capitalize;
  }
  .ag-grid-table.total .ag-row {
      /* background-color: #7c71f138; */
      font-weight: 700;
  }
  .ag-grid-table.total .ag-header {
      border: none;
  }
  /* .ag-grid-table.total .ag-row-first {
      border-top-width: 1px;
  } */
</style>
